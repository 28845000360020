import * as React from 'react';
import { useState } from 'react';
import { hot } from 'react-hot-loader';
import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from 'react-leaflet';
import styled from '@emotion/styled';
import DocsList from './DocsList';

const reactLogo = require('./../assets/img/react_logo.svg');

const MapBox = styled(MapContainer)`
  height: ${window.innerHeight - 20}px;
`;

const streets = [
  { name: 'Улица Академика Королева', lat: 55.82159871337457, lng: 37.61526256049425 },
  { name: 'Аргуновская', lat: 55.817156, lng: 37.620312 },
  { name: 'Большая Марьинская улица', lat: 55.80778811258886, lng: 37.63456922024489 },
  { name: 'Бочкова', lat: 55.810350007270266, lng: 37.632469302043326 },
  { name: 'Улица Валентины Леонтьевой', lat: 55.823432964450255, lng: 37.6195221953094 },
  { name: 'Территория Ввц', lat: 55.83457943020234, lng: 37.62167282402516 },
  { name: 'Годовикова', lat: 55.80731438617822, lng: 37.631075140088804 },
  { name: 'Проезд Дубовой Рощи', lat: 55.81809749962776, lng: 37.60968536138535 },
  { name: 'Улица Дубовой Рощи', lat: 55.82014012902127, lng: 37.60310783050955 },
  { name: 'Звёздный бульвар', lat: 55.8155892374406, lng: 37.63034683652222 },
  { name: 'Калибровская', lat: 55.80950661191182, lng: 37.62129119597376 },
  { name: 'Улица Кондратюка', lat: 55.81935147591387, lng: 37.634348105639226 },
  { name: 'Аллея Космонавтов', lat: 55.82307994360244, lng: 37.639115564525135 },
  { name: 'Крылатская 1-я улица' },
  { name: 'Мжд Казанское 5-й километр' },
  { name: 'Мурманский', lat: 55.808093014685745, lng: 37.61983400210739 },
  { name: 'Новомосковская улица', lat: 55.815688135598, lng: 37.61779359541834 },
  { name: 'Новоостанкинская 2-я улица', lat: 55.81534114324248, lng: 37.62428001500667 },
  { name: 'Новоостанкинская 3-я улица', lat: 55.818767933900745, lng: 37.62752640992404 },
  { name: 'Новоостанкинский 5-й проезд', lat: 55.817210994206256, lng: 37.62671143747867 },
  { name: 'Новоостанкинский 6-й проезд', lat: 55.81447407267941, lng: 37.6331267785281 },
  { name: 'Олонецкая улица' },
  { name: 'Проезд Ольминского', lat: 55.812535656702366, lng: 37.63161677867175 },
  { name: 'Останкинская 1-я улица', lat: 55.824183255539566, lng: 37.62254261411727 },
  { name: 'Останкинская 2-я улица', lat: 55.825397957199975, lng: 37.628064695745714 },
  { name: 'Останкинский проезд' },
  { name: 'Останкинский 3-й переулок', lat: 55.82470127047567, lng: 37.62594088912011 },
  { name: 'Останкинский 5-й переулок', lat: 55.824570613841324, lng: 37.630230747163296 },
  { name: 'Останкинский 6-й переулок', lat: 55.8226234248641, lng: 37.6309096813202 },
  { name: 'Поперечный 1-й проезд', lat: 55.82373960383402, lng: 37.640508720651276 },
  { name: 'Поперечный 2-й проезд', lat: 55.82429092477266, lng: 37.64195036608726 },
  { name: 'Улица Прасковьина', lat: 55.82736642879068, lng: 37.62421513907612 },
  { name: 'Продольный проезд', lat: 55.82389236937821, lng: 37.635852992534645 },
  { name: 'Прудовой проезд', lat: 55.81548836106111, lng: 37.6160288695246 },
  { name: 'Узкий переулок', lat: 55.80603755244939, lng: 37.63257156126202 },
  { name: 'Хованская улица', lat: 55.826401461717644, lng: 37.62149336747826 },
  { name: 'Улица Цандера', lat: 55.81801066301686, lng: 37.63053291477263 },
  { name: 'Широкий проезд', lat: 55.80492555282595, lng: 37.633464066311724 },
];

function LocationMarker() {
  const [position, setPosition] = useState(null);
  const map = useMapEvents({
    click(e) {
      setPosition(e.latlng);
    },
    locationfound(e) {
      setPosition(e.latlng);
      map.flyTo(e.latlng, map.getZoom());
    },
  });

  return position === null ? null : (
    <Marker position={position}>
      <Popup>{`, lat: ${position.lat}, lng: ${position.lng}`}</Popup>
    </Marker>
  );
}

class App extends React.Component<Record<string, unknown>, undefined> {
  public render() {
    return (
      <div className="app">
        <MapBox center={[55.820803010414174, 37.611034802009584]} zoom={16} scrollWheelZoom={true}>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {streets
            .filter((street) => street.lng > 0 && street.lat > 0)
            .map((street) => {
              return (
                <Marker position={[street.lat, street.lng]}>
                  <Popup minWidth={600} maxHeight={400} keepInView={true}>
                    <p>{street.name}</p>
                    <DocsList street={street.name} />
                  </Popup>
                </Marker>
              );
            })}
          <Marker position={[55.820803010414174, 37.611034802009584]}>
            <Popup>Останкинская Телебашня</Popup>
          </Marker>
          {/*<LocationMarker />*/}
        </MapBox>
      </div>
    );
  }
}

declare let module: Record<string, unknown>;

export default hot(module)(App);
